<template>
    <!-- SSL证书 -->
    <div class="theSsl">
        <div class="tsBox1">
            <!-- <img
        src="https://bce.bdstatic.com/p3m/common-service/uploads/sslbanner_632f51a.png"
        alt=""
        class="tb1Bg"
      /> -->
            <div class="tb1T">
                <p class="t1t1">SSL证书</p>
                <p class="t1t2">
                    提供威有品牌及全球多个知名品牌的DV、OV、EV证书，享受全球可信的服务器验证、 数据传输加密服务。威有超级SSL证书拥有多年签发、免部署、
                    访问加速、收录加速、搜索加权等独家优势权益！选购咨询热线：{{ getWebData().phone }}
                </p>
                <p class="t1tR">入选威开发季度产品热销版</p>
                <p class="t1tR2">威有上云节，威有自研证书首购4折起 | 立即抢购</p>
                <div class="t1t3">
                    <div class="t1B">想要</div>
                    <div class="t1B">帮助文档</div>
                </div>
            </div>
        </div>
        <div class="tsBox2">
            <div class="tb2Top">限时特惠</div>
            <div class="tb2Box" v-for="item in 2" :key="item">
                <div class="t2bLeft">
                    <div class="t2lBox">
                        <p class="t2l1">威有网站证书——DV单域名</p>
                        <p class="t2l2">
                            威有自研证书，全面对接国际、国内最值得信赖的第三方数字证书颁发机构(CA)，
                            确保数字证书认证可信力和证书加密强度、保障权益最大化，让用户真正安全可信赖。
                        </p>
                        <div class="t2l3">
                            <p class="tl31">适用场景</p>
                            <p class="tl32">小微企业、API服务、个人适用</p>
                        </div>
                        <div class="t2l4">
                            <div class="tl41">
                                <p class="t4p1">规格</p>
                                <p class="t4p2">DV单域名</p>
                            </div>
                            <div class="tl41">
                                <p class="t4p1">时长</p>
                                <p class="t4p2">一年</p>
                            </div>
                            <div class="tl41">
                                <p class="t4p1">保护域名</p>
                                <p class="t4p2">1个</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="t2bRight">
                    <div class="t2rBox">
                        <div class="t6Icon">
                            <img src="https://bce.bdstatic.com/p3m/common-service/uploads/jiaobiao_b3a6b8e.svg" alt="" />
                            <p class="t6ip">限时4折</p>
                        </div>
                        <div class="t2r1">
                            <p class="trp1">限购数量</p>
                            <p class="trp2">1个</p>
                        </div>
                        <div class="t2r1">
                            <p class="trp1">时长</p>
                            <p class="trp2">1年</p>
                        </div>
                        <div class="t2r2">
                            <div class="t2rb">首购优惠</div>
                            <div class="t2rb">省1280元</div>
                        </div>
                        <p class="t2r3">威有400</p>
                        <div class="t2r4">想要</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tsBox3">
            <div class="tb3Box">
                <div class="t3bTop">
                    <p class="t31">为什么要使用SSl证书？</p>
                    <p class="t32">向访客证明您的网站很可靠，值得信赖</p>
                </div>
                <div class="t3bList">
                    <ul>
                        <li class="t3bLi" v-for="item in 8" :key="item">
                            <div class="t3lBox">
                                <div class="t3l1">
                                    <img
                                        src="https://bce.bdstatic.com/p3m/common-service/uploads/%E5%8A%A0%E5%AF%86%E6%95%B0%E6%8D%AE_4280285.svg"
                                        alt=""
                                        class="t31Icon"
                                    />
                                </div>
                                <p class="t3l2">加密数据</p>
                                <p class="t3l3">保护网站访客的隐私信息不被监听，劫持窃取甚至篡改</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tsBox4">
            <div class="tb4Box">
                <div class="t4bTop">
                    <p class="t41">SSL证书应用场景</p>
                    <p class="t42">用一把锁一次性提升网络安全</p>
                </div>
                <div class="t4bList">
                    <ul>
                        <li class="t4bLi" v-for="item in 6" :key="item">
                            <div class="t4lBox">
                                <p class="t4l1">支付体系加密</p>
                                <p class="t4l2">
                                    支付环节是用户最敏感也最容易受到威胁的部分，极易成为不法
                                    用户信息劫持和伪装欺诈的重要目标。因此，实现网站支付环节的HTTPS信息传输加密， 已成为各大网站标配。
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tsBox5">
            <div class="tb5Box">
                <!-- <img
          src="https://bce.bdstatic.com/p3m/common-service/uploads/%E7%BC%96%E7%BB%84%206_c6e11e4.png"
          alt=""
          class="t5bImg"
        /> -->
                <div class="t5bTop">
                    <p class="t5t1">威有团队倾力打造 x 威有超级SSL证书</p>
                    <p class="t5t2">凝聚十大核心能力，助力企业轻松打造高安全高可信站点</p>
                </div>
                <div class="t5bList">
                    <ul>
                        <li class="t5Li" v-for="item in 10" :key="item">
                            <div class="t5l1">
                                <!-- <img src="" alt="" class="t51Img"> -->
                            </div>
                            <p class="t5l2">套餐自由组合</p>
                            <p class="t5l3">固定域名及泛域名，多个域名可自由组合签发</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tsBox6">
            <div class="tb6Top">限时特惠</div>
            <div class="tb6Boxs" v-for="item in 3" :key="item">
                <div class="t6bLeft">
                    <div class="t6lBox">
                        <p class="tl1">云虚拟机 入门型BC01</p>
                        <p class="tl2">
                            一站式零基础创建与运维网站，支持海量网站模板，基于容器技术， 热迁移技术发布的新一代网站主机服务，自有控制面板可视化操作。
                        </p>
                        <div class="tl3">
                            <p class="t3p">适用场景</p>
                            <ul>
                                <li class="t3Li" v-for="item in 6" :key="item">门户网站</li>
                            </ul>
                        </div>
                        <div class="tl4">
                            <div class="t41">
                                <p class="t41t">绑定域名</p>
                                <p class="t41b">50个</p>
                            </div>
                            <div class="t42">
                                <p class="t42t">支持语言</p>
                                <p class="t42b">PHP 5.2/5.3/5.4/5.6/7.0/7.3/7.4 + HTML + WAP</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="t6bRight">
                    <div class="t6rBox">
                        <div class="tr1">
                            <ul>
                                <li class="trLi">
                                    <p class="trl1">存储空间</p>
                                    <p class="trl2">1000M</p>
                                </li>
                                <li class="trLi">
                                    <p class="trl1">内存</p>
                                    <p class="trl2">128MB</p>
                                </li>
                                <li class="trLi">
                                    <p class="trl1">数据库</p>
                                    <p class="trl2">MySQL 5.7.17 / 1000M</p>
                                </li>
                                <li class="trLi">
                                    <p class="trl1">时长</p>
                                    <p class="trl2">12个月</p>
                                </li>
                            </ul>
                        </div>
                        <div class="tr2">热销机型</div>
                        <div class="tr3">
                            <p class="t31">威有149/年</p>
                            <p class="t32">199</p>
                        </div>
                        <div class="tr4">想要</div>
                    </div>
                    <div class="t6Icon">
                        <img src="https://bce.bdstatic.com/p3m/common-service/uploads/jiaobiao_b3a6b8e.svg" alt="" />
                        <p class="t6ip">7.5折</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="tsBox7">
            <div class="tb7Top">规格配置推荐</div>
            <div class="tb7Boxs">
                <div class="t7bTab">
                    <ul class="t7bUl">
                        <li class="t7tLi" :class="{ t7Active: t7A === 1 }" @click="getT7c(1)">经济型建站主机</li>
                        <li class="t7tLi" :class="{ t7Active: t7A === 2 }" @click="getT7c(2)">独享型建站主机</li>
                        <li class="t7tLi" :class="{ t7Active: t7A === 3 }" @click="getT7c(3)">功能型建站主机</li>
                        <li class="t7tLi" :class="{ t7Active: t7A === 4 }" @click="getT7c(4)">商务型建站主机</li>
                    </ul>
                </div>
                <div class="t7bContent" v-show="showT7c === item" v-for="item in 4" :key="item">
                    <ul class="t7cUl">
                        <li class="t7cLi" v-for="item in 4" :key="item">
                            <div class="t7cBox">
                                <div class="t71">
                                    <p class="t71T">入门型{{ item }}</p>
                                    <p class="t71B">个人兴趣建站</p>
                                </div>
                                <div class="t72">
                                    <p class="t72T">2G</p>
                                    <p class="t72B">网页空间</p>
                                </div>
                                <div class="t72">
                                    <p class="t72T">128MB</p>
                                    <p class="t72B">内存</p>
                                </div>
                                <div class="t72">
                                    <p class="t72T">1000MB</p>
                                    <p class="t72B">数据库</p>
                                </div>
                                <p class="t73">威有19.9/月起</p>
                                <div class="t74">想要</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tsBox8">
            <div class="tb8Top">网站托管专家</div>
            <div class="tb8List">
                <ul>
                    <li class="t8Li" v-for="item in 5" :key="item">
                        <img src="https://bce.bdstatic.com/p3m/common-service/uploads/imgcardbggrey_8680511.png" alt="" class="t8Bg" />
                        <div class="t8lBox">
                            <p class="t81">更高性能</p>
                            <p class="t82">多重加速措施+骨干网不限流量=网页秒开</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="tsBox9">
            <div class="tb9Top">热门爆款推荐</div>
            <div class="tb9List">
                <div class="tb9Box">
                    <ul>
                        <li class="t9Li" v-for="item in 5" :key="item">
                            <div class="t9lBox">
                                <div class="t91">
                                    <img src="https://bce.bdstatic.com/p3m/common-service/uploads/5@2x_5efc72d.png" alt="" class="t91Icon" />
                                </div>
                                <p class="t92">门户网站</p>
                                <div class="t93">
                                    <ul class="t93Ul">
                                        <li class="t93Li" v-for="item in 6" :key="item">威开发系统预装</li>
                                    </ul>
                                </div>
                                <div class="t94">想要</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tsBox10">
            <div class="tb10Top">安全稳定首选</div>
            <div class="tb10Box">
                <div class="tbbTop">
                    <div class="ttBoxs" v-for="item in 3" :key="item">
                        <p class="tbs1">故障自动恢复{{ item }}</p>
                        <p class="tbs2">机房实时监测，故障异常自动恢复，持久在线</p>
                    </div>
                </div>
                <div class="tbbBottom">
                    <div class="ttBoxs">
                        <p class="tbs1">故障自动恢复</p>
                        <p class="tbs2">机房实时监测，故障异常自动恢复，持久在线</p>
                    </div>
                    <div class="tbImg">
                        <img src="https://bce.bdstatic.com/p3m/common-service/uploads/gif_chanpinyoushi_d4d3694.gif" alt="" />
                    </div>
                    <div class="ttBoxs">
                        <p class="tbs1">故障自动恢复</p>
                        <p class="tbs2">机房实时监测，故障异常自动恢复，持久在线</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="tsBox11">
            <div class="tb11Top">客户案例</div>
            <div class="tb11Box">
                <div class="tb11Left">
                    <p class="t11l">一站式零基础创建与运维网站，支持海量网站模版。</p>
                    <p class="t11l2" v-for="item in 3" :key="item">快速收录</p>
                    <div class="t11l3">即刻使用</div>
                </div>
                <div class="tb11Right">
                    <ul>
                        <li class="t11RLi" v-for="item in 3" :key="item">
                            <div class="t11rBox">
                                <div class="trb1">
                                    <img src="https://bce.bdstatic.com/p3m/common-service/uploads/chaoyangquanwangtong_d489fe4.png" alt="" />
                                </div>
                                <p class="trb2">威有东莞网络技术公司</p>
                                <p class="trb3">
                                    威有东莞网络技术公司是互联网的网站建设提供商， 携手威有智能云全流程建站方案，为企业提供完整的互联网应用服务。
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tsBox12">
            <div class="tb12Top">使用指南</div>
            <div class="tb12Box">
                <div class="t12Boxs" v-for="item in 3" :key="item">
                    <div class="tbs1"></div>
                    <div class="tbs2">
                        <p class="ts21">新手入门</p>
                        <div class="ts22">
                            <p class="ts2p">仅需7步完成网站建设</p>
                            <p class="ts2p">选购主机操作步骤</p>
                            <p class="ts2p">轻松完成网站备案</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tsBox13">
            <div class="tb13Top">我们承诺</div>
            <div class="tb13List">
                <ul>
                    <li class="tb13Li" v-for="item in 5" :key="item">
                        <div class="t13lBox">
                            <div class="tlb1">
                                <img src="https://bce.bdstatic.com/portal-icons/32/fuwuhao@2x.png" alt="" />
                            </div>
                            <p class="tlb2">99.99%</p>
                            <p class="tlb3">SLA可用性</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheSsl',
    inject: ['getWebData'],
    data() {
        return {
            t7A: 1,
            showT7c: 1,
        };
    },
    methods: {
        getT7c(i) {
            this.t7A = i;
            this.showT7c = i;
        },
    },
};
</script>

<style lang="less" scoped>
.theSsl {
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 87px;
    overflow: hidden;
    .tsBox1 {
        width: 100%;
        height: 410px;
        overflow: hidden;
        position: relative;
        background: url('https://bce.bdstatic.com/p3m/common-service/uploads/sslbanner_632f51a.png') no-repeat center / auto 408px #dce7f3;
        .tb1Bg {
            width: 120%;
            height: 100%;
        }
        .tb1T {
            width: 40%;
            height: 280px;
            position: absolute;
            top: 80px;
            left: 100px;
            .t1t1 {
                width: 100%;
                height: 36px;
                line-height: 36px;
                font-size: 36px;
            }
            .t1t2 {
                width: 100%;
                height: 72px;
                font-size: 14px;
                margin-top: 20px;
            }
            .t1tR {
                width: 230px;
                height: 25px;
                line-height: 25px;
                font-size: 14px;
                color: #f33e3e;
                border-radius: 2px;
                margin-top: 10px;
                text-align: center;
                box-sizing: border-box;
                background: url(https://bce.bdstatic.com/p3m/common-service/uploads/hot-rank-icon_39bc8c5.png) no-repeat 6px center / 16px 16px
                    rgba(255, 255, 255, 0.4);
            }
            .t1tR2 {
                width: 320px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                font-size: 14px;
                color: #f33e3e;
                border-radius: 2px;
                margin-top: 10px;
                background-color: rgba(255, 255, 255, 0.4);
            }
            .t1t3 {
                width: 100%;
                height: 40px;
                margin-top: 20px;
                .t1B {
                    width: 136px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 5px;
                    background-color: #2468f2;
                    color: white;
                    float: left;
                }
                .t1B:nth-child(2) {
                    background-color: white;
                    border: 1px solid #2468f2;
                    color: #2468f2;
                    margin-left: 10px;
                }
            }
        }
    }
    .tsBox2 {
        width: 100%;
        height: 770px;
        overflow: hidden;
        .tb2Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 20px;
        }
        .tb2Box {
            width: 80%;
            height: 288px;
            margin: 0 auto;
            margin-top: 40px;
            background-color: #f8fafc;
            border-radius: 10px;
            box-shadow: 1px 1px 10px 1px gainsboro;
            .t2bLeft {
                width: 70%;
                height: 100%;
                float: left;
                .t2lBox {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    margin-top: 15px;
                    .t2l1 {
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 18px;
                    }
                    .t2l2 {
                        width: 100%;
                        height: 50px;
                        font-size: 14px;
                        color: gray;
                        margin-top: 10px;
                    }
                    .t2l3 {
                        width: 100%;
                        height: 50px;
                        margin-top: 15px;
                        .tl31 {
                            width: 100%;
                            height: 24px;
                            line-height: 24px;
                            color: #2468f2;
                            font-size: 14px;
                        }
                        .tl32 {
                            width: 100%;
                            height: 24px;
                            font-size: 14px;
                        }
                    }
                    .t2l4 {
                        width: 100%;
                        height: 50px;
                        margin-top: 20px;
                        .tl41 {
                            width: 150px;
                            height: 50px;
                            border-right: 1px solid gainsboro;
                            float: left;
                            text-align: center;
                            .t4p1 {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 14px;
                            }
                            .t4p2 {
                                width: 100%;
                                height: 25px;
                                line-height: 25px;
                                font-size: 16px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
            .t2bRight {
                width: 30%;
                height: 100%;
                float: left;
                background: #f2f5f9 url('https://bce.bdstatic.com/p3m/common-service/uploads/jgkp_c7b7205.png') no-repeat bottom center;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                position: relative;
                .t2rBox {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    margin-top: 15px;
                    .t2r1 {
                        width: 100%;
                        height: 25px;
                        font-size: 14px;
                        .trp1 {
                            width: 70px;
                            height: 25px;
                            line-height: 25px;
                            color: gray;
                            float: left;
                        }
                        .trp2 {
                            min-width: 22px;
                            height: 25px;
                            line-height: 25px;
                            float: left;
                            margin-left: 30px;
                        }
                    }
                    .t2r1:nth-child(2) {
                        margin-top: 10px;
                    }
                    .t2r2 {
                        width: 100%;
                        height: 25px;
                        margin-top: 30px;
                        .t2rb {
                            width: 66px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            border-radius: 5px;
                            border: 1px solid gray;
                            float: left;
                            font-size: 12px;
                        }
                        .t2rb:nth-child(2) {
                            margin-left: 10px;
                        }
                    }
                    .t2r3 {
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 32px;
                        color: #f33e3e;
                        margin-top: 25px;
                    }
                    .t2r4 {
                        width: 80%;
                        height: 44px;
                        line-height: 44px;
                        text-align: center;
                        background-color: #2468f2;
                        color: white;
                        font-size: 16px;
                        margin: 0 auto;
                        margin-top: 30px;
                        border-radius: 10px;
                    }
                    .t6Icon {
                        width: 57px;
                        height: 57px;
                        position: absolute;
                        right: -5px;
                        top: -5px;
                        .t6ip {
                            text-align: center;
                            transform: rotate(45deg);
                            display: inline-block;
                            width: 56px;
                            position: absolute;
                            top: 13px;
                            left: 7px;
                            color: white;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .tb2Box:hover {
            box-shadow: 1px 1px 10px 1px gray;
        }
    }
    .tsBox3 {
        width: 100%;
        height: 700px;
        .tb3Box {
            width: 85%;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .t3bTop {
                width: 100%;
                height: 70px;
                text-align: center;
                .t31 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 28px;
                }
                .t32 {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: gray;
                    margin-top: 10px;
                }
            }
            .t3bList {
                width: 100%;
                height: 550px;
                margin-top: 40px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .t3bLi {
                        width: 23%;
                        height: 243px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        .t3lBox {
                            width: 80%;
                            height: 80%;
                            margin: 0 auto;
                            margin-top: 10%;
                            .t3l1 {
                                width: 100%;
                                height: 70px;
                                .t31Icon {
                                    width: 70px;
                                    height: 70px;
                                    margin: 0 auto;
                                    display: block;
                                }
                            }
                            .t3l2 {
                                width: 100%;
                                height: 25px;
                                line-height: 25px;
                                text-align: center;
                                font-size: 18px;
                                margin-top: 10px;
                            }
                            .t3l3 {
                                width: 100%;
                                height: 48px;
                                font-size: 14px;
                                color: gray;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .tsBox4 {
        width: 100%;
        height: 625px;
        .tb4Box {
            width: 85%;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .t4bTop {
                width: 100%;
                height: 70px;
                margin-top: 20px;
                text-align: center;
                .t41 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 28px;
                }
                .t42 {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: gray;
                }
            }
            .t4bList {
                width: 100%;
                height: 420px;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .t4bLi {
                        width: 32%;
                        height: 193px;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        .t4lBox {
                            width: 80%;
                            height: 80%;
                            margin: 0 auto;
                            margin-top: 20px;
                            .t4l1 {
                                width: 100%;
                                height: 25px;
                                line-height: 25px;
                                font-size: 18px;
                            }
                            .t4l2 {
                                width: 100%;
                                height: 88px;
                                font-size: 14px;
                                color: gray;
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .tsBox5 {
        width: 100%;
        // height: 1257px;
        height: 750px;
        .tb5Box {
            width: 85%;
            height: 100%;
            margin: 0 auto;

            .t5bTop {
                width: 100%;
                height: 90px;
                text-align: center;
                .t5t1 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 30px;
                }
                .t5t2 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 15px;
                    color: gray;
                }
            }
            .t5bList {
                width: 100%;
                height: 500px;
                margin-top: 50px;

                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .t5Li {
                        width: 18%;
                        height: 200px;
                        margin-top: 10px;

                        .t5l1 {
                            width: 70px;
                            height: 70px;
                            background-color: #2468f2;
                            border-radius: 50%;
                            margin: 0 auto;
                            margin-top: 10px;
                        }
                        .t5l2 {
                            width: 100%;
                            height: 25px;
                            line-height: 25px;
                            font-size: 18px;
                            font-weight: 900;
                            text-align: center;
                            margin-top: 20px;
                        }
                        .t5l3 {
                            width: 100%;
                            height: 40px;
                            line-height: 20px;
                            text-align: center;
                            font-size: 14px;
                            color: gray;
                            margin-top: 20px;
                        }
                    }
                }
            }

            .t5bImg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .tsBox6 {
        width: 100%;
        height: 1072px;
        .tb6Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 28px;
            text-align: center;
        }
        .tb6Boxs {
            width: 80%;
            height: 288px;
            margin: 0 auto;
            margin-top: 40px;
            background-color: #f8fafc;
            border-radius: 10px;
            box-shadow: 1px 1px 10px 1px gainsboro;
            .t6bLeft {
                width: 70%;
                height: 100%;
                float: left;
                .t6lBox {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    margin-top: 2%;
                    .tl1 {
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 18px;
                    }
                    .tl2 {
                        width: 100%;
                        height: 48px;
                        line-height: 24px;
                        font-size: 14px;
                        color: gray;
                        margin-top: 10px;
                    }
                    .tl3 {
                        width: 100%;
                        height: 75px;
                        margin-top: 20px;
                        .t3p {
                            width: 100%;
                            height: 24px;
                            line-height: 24px;
                            font-size: 14px;
                            color: #2468f2;
                        }
                        ul {
                            width: 100%;
                            height: 48px;
                            margin-top: 5px;
                            display: flex;
                            justify-content: space-around;
                            flex-wrap: wrap;
                            .t3Li {
                                width: 33%;
                                height: 24px;
                                line-height: 24px;
                                font-size: 14px;
                                color: gray;
                            }
                        }
                    }
                    .tl4 {
                        width: 100%;
                        height: 50px;
                        margin-top: 30px;
                        .t41 {
                            width: 25%;
                            height: 50px;
                            float: left;
                            box-sizing: border-box;
                            border-right: 1px solid gainsboro;
                            .t41t {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 14px;
                                color: gray;
                            }
                            .t41b {
                                width: 100%;
                                height: 26px;
                                line-height: 26px;
                            }
                        }
                        .t42 {
                            width: 70%;
                            height: 50px;
                            float: left;
                            margin-left: 30px;
                            .t42t {
                                width: 100%;
                                height: 20px;
                                line-height: 20px;
                                font-size: 14px;
                                color: gray;
                            }
                            .t42b {
                                width: 100%;
                                height: 26px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }
            .t6bRight {
                width: 30%;
                height: 100%;
                float: left;
                position: relative;
                .t6rBox {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    margin-top: 5%;
                    .tr1 {
                        width: 100%;
                        height: 126px;
                        ul {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: space-around;
                            flex-wrap: wrap;
                            .trLi {
                                width: 100%;
                                height: 24px;
                                line-height: 24px;
                                .trl1 {
                                    width: 70px;
                                    height: 24px;
                                    font-size: 14px;
                                    color: gray;
                                    float: left;
                                }
                                .trl2 {
                                    min-width: 50px;
                                    height: 24px;
                                    float: left;
                                    margin-left: 40px;
                                }
                            }
                        }
                    }
                    .tr2 {
                        width: 66px;
                        height: 24px;
                        line-height: 24px;
                        margin-top: 5px;
                        font-size: 12px;
                        border-radius: 5px;
                        color: #f33e3e;
                        text-align: center;
                        box-shadow: 1px 1px 10px 1px #f33e3ebe;
                    }
                    .tr3 {
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        margin-top: 10px;
                        .t31 {
                            width: 120px;
                            height: 28px;
                            font-size: 28px;
                            float: left;
                            color: #f33e3e;
                            float: left;
                        }
                        .t32 {
                            width: 25px;
                            height: 28px;
                            float: left;
                            color: gray;
                            text-decoration: line-through;
                            margin-left: 10px;
                        }
                    }
                    .tr4 {
                        width: 280px;
                        height: 44px;
                        margin: 0 auto;
                        line-height: 44px;
                        text-align: center;
                        background-color: #2468f2;
                        color: white;
                        margin-top: 15px;
                        border-radius: 10px;
                    }
                }
                .t6Icon {
                    width: 57px;
                    height: 57px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    .t6ip {
                        text-align: center;
                        transform: rotate(45deg);
                        display: inline-block;
                        width: 56px;
                        position: absolute;
                        top: 9px;
                        left: 7px;
                        color: white;
                    }
                }
            }
        }
        .tb6Boxs:hover {
            box-shadow: 1px 1px 10px 1px gray;
        }
    }
    .tsBox7 {
        width: 100%;
        height: 784px;
        background-color: #f6f8fb;
        .tb7Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
        }
        .tb7Boxs {
            width: 85%;
            height: 640px;
            margin: 0 auto;
            margin-top: 30px;
            background-color: white;
            border-radius: 20px;
            box-shadow: 1px 1px 10px 1px gainsboro;
            .t7bTab {
                width: 100%;
                height: 60px;
                border-bottom: 1px solid gainsboro;
                .t7bUl {
                    width: 90%;
                    height: 60px;
                    margin: 0 auto;
                    line-height: 60px;
                    display: flex;
                    justify-content: space-around;
                    .t7tLi {
                        width: 25%;
                        height: 100%;
                        color: gray;
                        text-align: center;
                        cursor: pointer;
                    }
                    .t7Active {
                        border-bottom: 2px solid #2468f2;
                    }
                }
            }
            .t7bContent {
                width: 100%;
                height: 546px;
                margin-top: 25px;
                .t7cUl {
                    width: 95%;
                    height: 95%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: space-around;
                    .t7cLi {
                        width: 23%;
                        height: 100%;
                        background-color: white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        .t7cBox {
                            width: 85%;
                            height: 90%;
                            margin: 0 auto;
                            margin-top: 10%;
                            .t71 {
                                width: 100%;
                                height: 70px;
                                border-bottom: 1px solid gainsboro;
                                .t71T {
                                    width: 100%;
                                    height: 30px;
                                    line-height: 30px;
                                    text-align: center;
                                    font-size: 18px;
                                }
                                .t71B {
                                    width: 100%;
                                    height: 25px;
                                    line-height: 25px;
                                    text-align: center;
                                    font-size: 12px;
                                    color: gray;
                                }
                            }
                            .t72 {
                                width: 100%;
                                height: 60px;
                                margin-top: 20px;
                                text-align: center;
                                line-height: 30px;
                                font-size: 14px;
                                .t72T {
                                    width: 100%;
                                    height: 30px;
                                }
                                .t72B {
                                    width: 100%;
                                    height: 30px;
                                    color: gray;
                                }
                            }
                            .t73 {
                                width: 100%;
                                height: 58px;
                                text-align: center;
                                line-height: 58px;
                                font-size: 30px;
                                color: #d0021b;
                                margin-top: 20px;
                            }
                            .t74 {
                                width: 160px;
                                height: 38px;
                                margin: 0 auto;
                                margin-top: 30px;
                                line-height: 38px;
                                background-color: #2468f2;
                                font-size: 14px;
                                border-radius: 20px;
                                color: white;
                                text-align: center;
                            }
                        }
                    }
                    .t7cLi:hover {
                        box-shadow: 1px 1px 10px 1px gray;
                        transition: 0.5s;
                    }
                }
            }
        }
    }
    .tsBox8 {
        width: 100%;
        height: 376px;
        overflow: hidden;
        .tb8Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 30px;
        }
        .tb8List {
            width: 85%;
            height: 200px;
            margin: 0 auto;
            margin-top: 40px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .t8Li {
                    width: 19%;
                    height: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    transition: 0.5s;
                    .t8Bg {
                        width: 400px;
                        height: 200px;
                        position: absolute;
                        left: -100px;
                    }
                    .t8lBox {
                        width: 80%;
                        height: 90%;
                        margin: 0 auto;
                        margin-top: 5%;
                        position: relative;
                        .t81 {
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            font-size: 18px;
                        }
                        .t82 {
                            width: 100%;
                            height: 48px;
                            line-height: 24px;
                            font-size: 14px;
                            color: gray;
                            margin-top: 20px;
                        }
                    }
                }
                .t8Li:hover {
                    transform: translateY(-20px);
                }
            }
        }
    }
    .tsBox9 {
        width: 100%;
        height: 634px;
        overflow: hidden;
        background-color: #f6f8fb;
        .tb9Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 40px;
        }
        .tb9List {
            width: 85%;
            height: 458px;
            margin: 0 auto;
            margin-top: 40px;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 1px 1px 10px 1px gainsboro;
            .tb9Box {
                width: 95%;
                height: 90%;
                margin: 0 auto;
                margin-top: 2%;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .t9Li {
                        width: 20%;
                        height: 100%;
                        border-right: 1px solid gainsboro;
                        .t9lBox {
                            width: 85%;
                            height: 90%;
                            margin: 0 auto;
                            margin-top: 5%;
                            overflow: hidden;
                        }
                        .t91 {
                            width: 60px;
                            height: 60px;
                            margin: 0 auto;
                            margin-top: 10px;
                            border-radius: 15px;
                            background: linear-gradient(-180deg, #71efff 0, #2cb1ff 100%);
                            box-shadow: 0 5px 20px 0 #b6f2ff;
                            overflow: hidden;
                            img {
                                width: 32px;
                                height: 32px;
                                margin: 0 auto;
                                margin-top: 15px;
                                display: block;
                            }
                        }
                        .t92 {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            font-size: 18px;
                            margin-top: 20px;
                            text-align: center;
                        }
                        .t93 {
                            width: 100%;
                            height: 194px;
                            margin-top: 20px;
                            .t93Ul {
                                width: 100%;
                                height: 100px;
                                display: flex;
                                justify-content: space-around;
                                flex-wrap: wrap;
                                .t93Li {
                                    width: 100%;
                                    height: 25px;
                                    line-height: 25px;
                                    font-size: 12px;
                                    text-align: center;
                                    color: gray;
                                }
                            }
                        }
                        .t94 {
                            width: 80px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border-radius: 20px;
                            box-shadow: 1px 1px 10px 1px gainsboro;
                            margin: 0 auto;
                        }
                    }
                    .t9Li:nth-child(5) {
                        border: none;
                    }
                }
            }
        }
    }
    .tsBox10 {
        width: 100%;
        height: 755px;
        .tb10Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 40px;
        }
        .tb10Box {
            width: 1280px;
            height: 579px;
            margin: 0 auto;
            margin-top: 40px;
            .tbbTop {
                width: 100%;
                height: 235px;
                .ttBoxs {
                    width: 280px;
                    height: 148px;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    background-color: white;
                    float: left;
                    margin-left: 100px;
                    .tbs1 {
                        width: 90%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 18px;
                        margin: 0 auto;
                        margin-top: 20px;
                    }
                    .tbs2 {
                        width: 90%;
                        height: 48px;
                        line-height: 24px;
                        font-size: 14px;
                        margin: 0 auto;
                        margin-top: 20px;
                    }
                }
                .ttBoxs:nth-child(1),
                .ttBoxs:nth-child(3) {
                    margin-top: 50px;
                }
            }
            .tbbBottom {
                width: 100%;
                height: 344px;
                .ttBoxs {
                    width: 280px;
                    height: 148px;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    background-color: white;
                    float: left;
                    margin-top: 80px;
                    margin-left: 50px;
                    .tbs1 {
                        width: 90%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 18px;
                        margin: 0 auto;
                        margin-top: 20px;
                    }
                    .tbs2 {
                        width: 90%;
                        height: 48px;
                        line-height: 24px;
                        font-size: 14px;
                        margin: 0 auto;
                        margin-top: 20px;
                    }
                }
                .tbImg {
                    width: 460px;
                    height: 344px;
                    float: left;
                    margin-left: 100px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .tsBox11 {
        width: 100%;
        height: 522px;
        overflow: hidden;
        background: #f0f3f7 url(https://bce.bdstatic.com/p3m/common-service/uploads/leftbg_grey_572d561.png) top right;
        .tb11Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 40px;
        }
        .tb11Box {
            width: 80%;
            height: 366px;
            margin: 0 auto;
            margin-top: 50px;
            .tb11Left {
                width: 20%;
                height: 100%;
                float: left;
                .t11l {
                    width: 180px;
                    height: 48px;
                    line-height: 24px;
                    font-size: 14px;
                    margin-top: 30px;
                }
                .t11l2 {
                    width: 180px;
                    height: 22px;
                    line-height: 22px;
                    font-size: 14px;
                    margin-top: 20px;
                }
                .t11l3 {
                    width: 136px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    border-radius: 10px;
                    border: 1px solid #2468f2;
                    color: #2468f2;
                    margin-top: 30px;
                }
            }
            .tb11Right {
                width: 80%;
                height: 100%;
                float: left;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .t11RLi {
                        width: 32%;
                        height: 100%;
                        background-color: white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        border-radius: 10px;
                        .t11rBox {
                            width: 90%;
                            height: 90%;
                            margin: 0 auto;
                            margin-top: 2%;
                            .trb1 {
                                width: 196px;
                                height: 106px;
                                background-color: white;
                                margin-top: -25px;
                                margin-left: 10px;
                                border-radius: 10px;
                                img {
                                    width: 95%;
                                    height: 95%;
                                    margin: 0 auto;
                                    display: block;
                                }
                            }
                            .trb2 {
                                width: 100%;
                                height: 28px;
                                line-height: 28px;
                                font-size: 18px;
                                margin-top: 40px;
                            }
                            .trb3 {
                                width: 90%;
                                height: 96px;
                                font-size: 14px;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .tsBox12 {
        width: 100%;
        height: 379px;
        overflow: hidden;
        .tb12Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 40px;
        }
        .tb12Box {
            width: 85%;
            height: 203px;
            margin: 0 auto;
            margin-top: 40px;
            display: flex;
            justify-content: space-around;
            .t12Boxs {
                width: 361px;
                height: 203px;
                border-radius: 5px;
                box-shadow: 1px 1px 10px 1px gainsboro;
                background-color: white;
                .tbs1 {
                    width: 100%;
                    height: 8px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    background-color: #2468f2;
                }
                .tbs2 {
                    width: 90%;
                    height: 180px;
                    margin: 0 auto;
                    overflow: hidden;
                    .ts21 {
                        width: 100%;
                        height: 36px;
                        line-height: 36px;
                        font-size: 18px;
                        margin-top: 20px;
                    }
                    .ts22 {
                        width: 100%;
                        height: 94px;
                        margin-top: 20px;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .ts2p {
                            width: 100%;
                            height: 20px;
                            line-height: 20px;
                            font-size: 14px;
                            color: #2468f2;
                        }
                    }
                }
            }
        }
    }
    .tsBox13 {
        width: 100%;
        height: 349px;
        overflow: hidden;
        .tb13Top {
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 28px;
            margin-top: 40px;
        }
        .tb13List {
            width: 85%;
            height: 173px;
            margin: 0 auto;
            margin-top: 40px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .tb13Li {
                    width: 19%;
                    height: 173px;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    background-color: white;
                    .t13lBox {
                        width: 75%;
                        height: 75%;
                        margin: 0 auto;
                        margin-top: 20px;
                        .tlb1 {
                            width: 48px;
                            height: 48px;
                            margin: 0 auto;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .tlb2 {
                            width: 100%;
                            height: 25px;
                            line-height: 25px;
                            text-align: center;
                            font-size: 18px;
                            margin-top: 20px;
                        }
                        .tlb3 {
                            width: 100%;
                            height: 24px;
                            line-height: 24px;
                            font-size: 14px;
                            text-align: center;
                            color: gray;
                        }
                    }
                }
            }
        }
    }
}
</style>
